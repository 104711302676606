import Swiper from "swiper"
import { find } from "utils/dom"
import big_number from "../modules/bigNumber"


export default function () {
	const Home = find("[js-page='home']")
	if (!Home) return




	big_number();
}