import { find, findAll } from "../utils/dom";
import { CountUp } from "countup.js";

const big_number = () => {
    const big_number = findAll('.big-number');
    if (!big_number) return;

    big_number.forEach((item) => {
        const value = parseInt(item.innerText);
        let countUp = new CountUp(item, value, { enableScrollSpy: true });
        countUp.start();
    })
}

export default big_number;