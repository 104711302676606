import { find, findAll } from "../utils/dom"


export const debugContato = () => {
    const Contatos = findAll('[js-debug-contato]');
    if (!Contatos) return;

    Contatos.forEach((contato) => {
        if (contato.href.includes('mailto.com')) {
            const prefixo = `${contato.href.replace('http://', '').replace('.com', '').replace('/', '')}`;
            const contatoInfo = contato.innerText;
            contato.href = `${prefixo}:${contatoInfo}`;
        } else if (contato.href.includes('tel.com')) {
            const href = contato.href;
            const prefixo = href.replace('http://', '').replace('.com', '').replace('/', '');
            const contatoInfo = contato.innerText.replaceAll('(', '').replaceAll(')', '').replaceAll(' ', '').replaceAll('-', '');
            contato.href = `${prefixo}:+55${contatoInfo}`;
        }
    })
}