import axios from "axios";
import { find, findAll, offset } from "../utils/dom";
import Swiper from "swiper";



const News = async () => {
    const page = find("[js-page='news']");
    if (!page) return;

    const BASEURL = `${window.location.protocol}//${window.location.host}`;
    const NOTICIA_AVISO = find('[js-result-pesquisa]');

    function cardBlog(dados) {

        const banner = dados['thumbnail'] || 'https://django-insentoo.s3.amazonaws.com/static/images/default/default.webp';
        const slug = `${BASEURL}/blog/${dados['slug']}`;
        const dataCriacao = new Date(dados['data_criacao']);
        const resumo = dados['resumo'];
        const titulo = dados['titulo'];
        const Meses = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"];

        return (
            `
                <div>
                    <img src="${banner}" loading="lazy" sizes="(max-width: 1439px) 90vw, 1200px" srcset="${banner}  500w, ${banner}  800w, ${banner}  1080w, ${banner}  1600w, ${banner}  2000w, ${banner}  2600w, ${banner}  3200w, ${banner}  7952w" alt="" class="image card-blog">
                   
                    <div class="margin-15px">
                    <h3 class="heading-5">${titulo}</h3>
                    </div>

                    <div class="margin-20px !z-0">
                    <div class="button-flex-category">
                        <a href="${slug}" class="button-line w-inline-block">
                        <div>Saiba mais</div><img src="https://django-insentoo.s3.amazonaws.com/static/images/next-white.svg" loading="lazy" alt="" class="icon-line">
                        </a>
                        <div>${(dataCriacao.getDate() <= 9) ? `0${dataCriacao.getDate()}` : dataCriacao.getDate()} ${Meses[dataCriacao.getMonth()]}, ${dataCriacao.getFullYear()}</div>
                    </div>
                    </div>
                </div>
            `
        )
    }



    function makerPost(dados = null, is_reset = false) {
        const contentBlog = find('[js-content-blog]');
        if (!contentBlog) return;

        if (is_reset) {
            contentBlog.innerHTML = "";
        }

        if (dados != null) {
            contentBlog.insertAdjacentHTML("beforeend", cardBlog(dados));
        }
    }


    async function loadingDados(limit, offset, busca = '', is_reset = false) {

        try {
            const result = await axios.get(`${BASEURL}/api/posts?limit=${limit}&offset=${offset}${busca}`);

            if (result.data.results.length == 0) {
                makerPost(null, is_reset);
                NOTICIA_AVISO.classList.remove('hidden');
                return;
            }

            NOTICIA_AVISO.classList.add("hidden");
            for (let [index, dados] of result.data.results.entries()) {

                if (index == 1) is_reset = false;

                makerPost(dados, is_reset)
            }

        } catch (error) {

        }


    }

    function resetBotaoMais() {
        const bt = find('[js-button-more-posts]');
        if (!bt) return;
        bt.dataset.post = 0;
    }

    function addCategoriaBotaoMais(categoria) {
        const bt = find('[js-button-more-posts]');
        if (!bt) return;
        bt.dataset.categoria = categoria;
    }

    function addSearchBotaoMais(search) {
        const bt = find('[js-button-more-posts]');
        if (!bt) return;
        bt.dataset.pesquisa = search;
    }

    function handleBotaoMais() {

        const bt = find('[js-button-more-posts]');
        if (!bt) return;

        bt.addEventListener('click', (e) => {
            e.preventDefault();

            const limit = parseInt(e.target.dataset.post) + 1;
            e.target.dataset.post = limit;
            const is_categoria = (e.target.dataset.categoria != '') ? `&categoria=${e.target.dataset.categoria}` : '';
            const is_pesquisa = (e.target.dataset.pesquisa != '') ? `&search=${e.target.dataset.pesquisa}` : '';


            loadingDados(6, limit * 6, `${is_categoria}${is_pesquisa}`);

        })
    }

    function removeDestaqueCategoria() {
        const bts = findAll('[js-button-categoria].filtro-select');
        bts.forEach((bt) => {
            bt.classList.remove('filtro-select');
            bt.classList.add('filtro-of');
        })

    }
    function handleBotaoCategoria() {
        const bts = findAll('[js-button-categoria]');
        if (!bts) return;

        bts.forEach((bt) => {
            bt.addEventListener("click", (e) => {
                e.preventDefault();

                removeDestaqueCategoria();
                e.target.classList.add('filtro-select');

                const busca = e.target.dataset.categoria;
                if (busca != '') {
                    addCategoriaBotaoMais(busca)
                    loadingDados(6, 0, `&categoria=${busca}`, true);
                }
                else {
                    loadingDados(6, 0, '', true);
                    addCategoriaBotaoMais('');
                }
                addSearchBotaoMais('');
                resetBotaoMais();
            })
        })
    }

    /** Função iniciadora do evento do form de pesquisa por nome */
    function handleFormPesquisa() {
        const form = find('#form-pesquisa');
        if (!form) return;

        form.addEventListener("click", async (e) => {
            e.preventDefault();

            const dados = new FormData(form);
            const pesquisa = dados.get('query');


            await loadingDados(6, 0, `&search=${pesquisa}`, true);
            addSearchBotaoMais(pesquisa);
            resetBotaoMais();

        })
    }

    function handlePopUp() {
        const PopUP = find('[js-pop-up]');
        if (!PopUP) return;

        const fecharPopUP = find('[js-pop-up-closed]');

        window.addEventListener('mousemove', function (e) {
            if (e.clientY < 20 && !sessionStorage.getItem("pop-up")) {
                PopUP.style.display = 'flex';
            }
        });

        fecharPopUP?.addEventListener("click", () => {
            PopUP.style.display = 'none';
            sessionStorage.setItem("pop-up", true);
        })
    }


    function handleSwiperTags() {
        const swiper = new Swiper('.swiper-tags', {
            slidesPerView: 'auto',
            spaceBetween: '8px',
        })
    }


    loadingDados(6, 0);
    handleBotaoMais();
    handleBotaoCategoria();
    handleFormPesquisa();
    handlePopUp();
    handleSwiperTags();
}

export default News;